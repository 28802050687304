export const EMAIL_REGEX =
  /^[A-Za-z0-9_+#*&?$!.-]+@[A-Za-z0-9_+#*&?$!.-]+\.[A-Za-z]{2,}$/;

export const uncheckedIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8158_389)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.21429 1.66367C2.3464 1.66367 1.64286 2.3672 1.64286 3.2351V11.8065C1.64286 12.6744 2.3464 13.378 3.21429 13.378H11.7857C12.6536 13.378 13.3571 12.6744 13.3571 11.8065V3.2351C13.3571 2.36719 12.6536 1.66367 11.7857 1.66367H3.21429ZM0.5 3.2351C0.5 1.736 1.71523 0.520813 3.21429 0.520813H11.7857C13.2848 0.520813 14.5 1.73601 14.5 3.2351V11.8065C14.5 13.3056 13.2848 14.5208 11.7857 14.5208H3.21429C1.71523 14.5208 0.5 13.3056 0.5 11.8065V3.2351Z"
        fill="#5849A6"
      />
    </g>
    <defs>
      <clipPath id="clip0_8158_389">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.020813)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const checkedIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8158_388)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.21429 2.16367C2.62254 2.16367 2.14286 2.64334 2.14286 3.2351V11.8065C2.14286 12.3983 2.62254 12.878 3.21429 12.878H11.7857C12.3775 12.878 12.8571 12.3983 12.8571 11.8065V3.2351C12.8571 2.64333 12.3775 2.16367 11.7857 2.16367H3.21429ZM0 3.2351C0 1.45985 1.43909 0.020813 3.21429 0.020813H11.7857C13.5609 0.020813 15 1.45987 15 3.2351V11.8065C15 13.5818 13.5609 15.0208 11.7857 15.0208H3.21429C1.43909 15.0208 0 13.5818 0 11.8065V3.2351Z"
        fill="#5849A6"
      />
      <path
        d="M10.3495 4.37799C10.1358 4.38421 9.93293 4.47335 9.7838 4.62656C8.50038 5.91281 7.51124 6.99163 6.33524 8.19506L5.13982 7.18428C4.9731 7.04339 4.75726 6.97449 4.53977 6.9927C4.32228 7.01103 4.12096 7.11495 3.98009 7.28167C3.83923 7.44838 3.77036 7.66417 3.78863 7.88167C3.80689 8.09917 3.91081 8.30049 4.07753 8.44138L5.8598 9.94931C6.01741 10.0828 6.21947 10.1521 6.42582 10.1435C6.63216 10.1348 6.82769 10.0487 6.97352 9.90249C8.45238 8.42028 9.50381 7.23796 10.9495 5.78821C11.0675 5.67238 11.1479 5.52356 11.1802 5.36135C11.2124 5.19913 11.1949 5.03092 11.1302 4.87878C11.0654 4.72663 10.9563 4.59742 10.817 4.50817C10.6778 4.41903 10.5149 4.37328 10.3495 4.37799Z"
        fill="#5849A6"
      />
    </g>
    <defs>
      <clipPath id="clip0_8158_388">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.020813)"
        />
      </clipPath>
    </defs>
  </svg>
);
